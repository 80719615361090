import { useEffect, useState } from 'react'

export const useModuleFromWindow = <T>(moduleName: string, interval = 100, timeout = 5000) => {
  const [moduleLoaded, setModuleLoaded] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    let elapsedTime = 0
    const intervalId = setInterval(() => {
      if (window[moduleName]) {
        clearInterval(intervalId)
        setModuleLoaded(true)
      }

      elapsedTime += interval
      if (elapsedTime >= timeout) {
        clearInterval(intervalId)
        setError(`Module ${moduleName} not available in window after ${timeout}ms`)
      }
    }, interval)

    return () => clearInterval(intervalId) // Cleanup interval on component unmount
  }, [moduleName, interval, timeout])

  return { module: moduleLoaded ? (window[moduleName] as T) : null, error }
}
